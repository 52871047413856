.two{
  flex: 1;
  width: 100%;
  display: flex;
  animation: fadeOut .5s ease-in-out;

  @keyframes fadeOut {
    0% {opacity: 0}
    100% {opacity: 1}  
  }

  &-container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &-circle:active{
    background-color: rgb(7, 4, 7);
  }

  &-color{
    background-color: rgba(32, 178, 171, 0.8);
  }
}