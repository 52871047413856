.eight{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation: fadeOut .5s ease-in-out;
    
    @keyframes fadeOut {
        0% {opacity: 0}
        100% {opacity: 1}  
    }
    
}
