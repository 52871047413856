.three{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeOut .5s ease-in-out;
    font-family: 'Rubik', sans-serif;

    &-container{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    &-wrapper{
        position: relative;
    }

    &-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;


        &-letter{
            position: absolute;
            top: 0;
            text-transform: uppercase;
            color: grey;
            z-index: -1;
        }
    }
}
