.input{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3em;
    position: absolute;
    bottom: 0;
    left: 0;

    &-icon{
        position: relative;
        color: rgb(215, 215, 215);
        padding-bottom: 3em;
    }

    &-text{
        border: none;
        border-bottom: 1px solid lightgrey;
        background-color: transparent;
        text-transform: uppercase;
        text-align: center;
        font-size: 2em;
        color: lightgrey;
        padding: .3em;
        font-family: 'Rubik', sans-serif;

    }

    &-text:focus, input:focus{
        outline: none;
    }
}

@media screen and (max-width: 480px) {
    .input{
        text-align: center;
    }

    .input-text{
        width: 60%;
    }
  }