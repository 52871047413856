.welcome{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(55, 50, 50);

  &-title{
    font-family: 'Zen Dots', cursive;
    color: #fff;
    font-size: 2.1em;
  }

  &-circle{
    height: 100px;
    width: 100px;
    border: 1px dashed #fff;
    border-radius: 50%;
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .welcome-circle{
    height: 60px;
    width: 60px;
  }
}