.eleven{
    flex-direction: column;
    justify-content: center;
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: lightgrey;
    font-family: 'Rubik', sans-serif;

    &-icon{
        padding-top: 1em;
    }
}