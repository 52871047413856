.input{
    // height: 150px;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: center;
    // padding-bottom: 3em;
    // position: absolute;
    // bottom: 0;
    // left: 0;

    // &-icon{
    //     position: relative;
    //     top: 0;
    //     color: rgb(186, 186, 186);
    // }

    // &-text{
    //     border: none;
    //     border-bottom: 1px solid skyblue;
    //     background-color: transparent;
    //     font-size: 1.5em;
    //     text-transform: uppercase;
    //     text-align: center;
    //     color: grey;
    //     padding: .3em;
    // }

    &-text:focus, input:focus{
        outline: none;
    }
}