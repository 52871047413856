.ten{
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
    color: lightgrey;
    font-family: 'Rubik', sans-serif;
}

.ten:focus{
    outline-width: 0;
}