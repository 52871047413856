.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-icon{
  position: absolute;
  top: 10px;
  left: 10px;
}

.grey-circle{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: lightgrey;
}

.item-animated{
  animation: fadeOut .5s ease-in-out;
}
// @keyframes fadeInOut {
//   0% {opacity: 0}
//   100% {opacity: 1}  
//   // 100% {opacity: 0}  
// }

.full-container{
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  animation: fadeOut .5s ease-in-out;

}

@keyframes fadeOut {
  0% {opacity: 0}
  100% {opacity: 1}  
}

@media screen and (max-width: 480px) {
  .grey-circle{
    height: 60px;
    width: 60px;
  }
}