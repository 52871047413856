.seven{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    animation: fadeOut .5s ease-in-out;
    position: relative;
    font-family: 'Rubik', sans-serif;

    
    @keyframes fadeOut {
        0% {opacity: 0}
        100% {opacity: 1}  
    }

    &-wrapper, &-wrapper-one, &-wrapper-two{
        position: relative;
    }

    &-container{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        // text-transform: lowercase;
    }
    
    &-reversed{
        transform: rotateX(180deg);
    }

    &-icon{
        position: absolute;
        top: 200px;
        margin: auto;
        color: rgb(166, 166, 166);
        animation: fadeIn 3s ease-in-out;

        &-hidden{
            display: none;
        }
    }
    @keyframes fadeIn {
        0% {opacity: 0}
        100% {opacity: 1} 
    }
    
    &-hidden{
        visibility: hidden;
    }
}

@media screen and (max-width: 480px) {
    .seven-container{
        flex-direction: column;
    }

    .seven-wrapper{
        display: flex;
    }

    .seven-icon{
        position: absolute;
        top: 50%;
        left: 30%;
    }
}

