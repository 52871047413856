.six{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation: fadeOut .5s ease-in-out;
    
    @keyframes fadeOut {
        0% {opacity: 0}
        100% {opacity: 1}  
    }

    &-circle{
        font-family: 'Rubik', sans-serif;
        color: grey;
    }

    &-circle-item{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8em;
    }
    
}
